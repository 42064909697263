var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staff"},[_c('div',[_c('van-icon',{staticClass:"return-icon",attrs:{"name":"arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('van-image',{attrs:{"width":"100%","height":"154px","src":require('@/assets/yuangong-banner.png')}})],1),_c('div',{staticClass:"staff-search"},[_c('div',[_c('div',{on:{"click":function($event){_vm.showPicker = true}}},[_c('span',[_vm._v(_vm._s(_vm.department))]),_c('van-icon',{attrs:{"name":"arrow-down"}})],1),_c('van-search',{attrs:{"placeholder":"搜姓名、部门"},on:{"click":function($event){_vm.showSearch = true}},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}})],1),_c('van-icon',{staticClass:"return-icon",attrs:{"name":"plus"},on:{"click":function($event){return _vm.$router.push({ path: '/SmartClassroom/AddStaff' })}}})],1),_c('div',{staticClass:"staff-list",on:{"scroll":_vm.turnpageFunc}},[_c('van-grid',{attrs:{"gutter":10,"column-num":"3"}},_vm._l((_vm.peopleList),function(item,i){return _c('van-grid-item',{key:i,on:{"click":function($event){return _vm.$router.push({
						path: '/SmartClassroom/StaffManagementDetails',
						query: { id: item.id },
					})}}},[_c('div',{staticClass:"s-list-item"},[_c('van-image',{attrs:{"src":item.UserPhoto}}),_c('div',{staticClass:"staff-name"},[_c('span',[_vm._v(_vm._s(item.Name))]),_c('i',{staticClass:"iconfont icon-yuangong-ren"})]),_c('div',{staticClass:"staff-occupation"},[_vm._v(_vm._s(item.Position))])],1)])}),1),_c('div',{staticClass:"data-status"},[(!_vm.peopleList.length)?_c('van-empty',{attrs:{"description":"暂无数据"}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.peopleList.length),expression:"peopleList.length"}]},[(_vm.isLoad)?_c('van-loading',{attrs:{"size":"14"}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoad),expression:"isLoad"}]},[_vm._v("数据加载中…")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoad),expression:"!isLoad"}]},[_vm._v("没有更多了")])],1)],1)],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.columns},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showPicker = false}}})],1),_c('van-popup',{staticStyle:{"height":"100%"},attrs:{"position":"bottom"},model:{value:(_vm.showSearch),callback:function ($$v) {_vm.showSearch=$$v},expression:"showSearch"}},[_c('search-block',{attrs:{"placeholder":"搜姓名、部门","dataList":_vm.dataList},on:{"CancelSearch":function () {
					_vm.showSearch = false
					_vm.searchVal = ''
					this$1.pageIndex = 1
					this$1.peopleList = []
					this$1.pagerecords = 0
					this$1.getPeopleList()
				},"SearchLoad":_vm.onLoad,"OnSearch":_vm.onSearch,"clickSingle":_vm.setData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }