<template>
	<div class="staff">
		<div>
			<van-icon @click="$router.go(-1)" class="return-icon" name="arrow-left" />
			<van-image width="100%" height="154px" :src="require('@/assets/yuangong-banner.png')" />
		</div>
		<div class="staff-search">
			<div>
				<div @click="showPicker = true">
					<span>{{ department }}</span>
					<van-icon name="arrow-down" />
				</div>
				<van-search
					v-model="searchVal"
					@click="showSearch = true"
					placeholder="搜姓名、部门"
				/>
			</div>
			<van-icon
				@click="$router.push({ path: '/SmartClassroom/AddStaff' })"
				class="return-icon"
				name="plus"
			/>
		</div>
		<div class="staff-list" @scroll="turnpageFunc">
			<van-grid :gutter="10" column-num="3">
				<van-grid-item
					v-for="(item, i) in peopleList"
					:key="i"
					@click="
						$router.push({
							path: '/SmartClassroom/StaffManagementDetails',
							query: { id: item.id },
						})
					"
				>
					<div class="s-list-item">
						<van-image :src="item.UserPhoto" />
						<div class="staff-name">
							<span>{{ item.Name }}</span>
							<i class="iconfont icon-yuangong-ren"></i>
						</div>
						<div class="staff-occupation">{{ item.Position }}</div>
					</div>
				</van-grid-item>
			</van-grid>
			<div class="data-status">
				<van-empty v-if="!peopleList.length" description="暂无数据" />
				<div v-show="peopleList.length">
					<van-loading v-if="isLoad" size="14" />
					<span v-show="isLoad">数据加载中…</span>
					<div v-show="!isLoad">没有更多了</div>
				</div>
			</div>
		</div>

		<van-popup v-model="showPicker" position="bottom">
			<van-picker
				show-toolbar
				:columns="columns"
				@confirm="onConfirm"
				@cancel="showPicker = false"
			/>
		</van-popup>
		<van-popup v-model="showSearch" position="bottom" style="height: 100%">
			<search-block
				placeholder="搜姓名、部门"
				@CancelSearch="
					() => {
						showSearch = false
						searchVal = ''
						this.pageIndex = 1
						this.peopleList = []
						this.pagerecords = 0
						this.getPeopleList()
					}
				"
				@SearchLoad="onLoad"
				@OnSearch="onSearch"
				:dataList="dataList"
				@clickSingle="setData"
			></search-block>
		</van-popup>
	</div>
</template>
<script>
import { GetByDepartmentOrName } from '@/api/PeopleManagement/people.js'
import {
	Image as VanImage,
	Grid,
	GridItem,
	Switch,
	Slider,
	Popup,
	Picker,
	Search,
	Loading,
	Empty,
} from 'vant'
import SearchBlock from '@/components/SearchBlock.vue'
export default {
	components: {
		[Grid.name]: Grid,
		[Loading.name]: Loading,
		[Empty.name]: Empty,
		[GridItem.name]: GridItem,
		[Switch.name]: Switch,
		[VanImage.name]: VanImage,
		[Slider.name]: Slider,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Search.name]: Search,
		SearchBlock,
	},
	data() {
		return {
			department: '全部部门',
			showPicker: false,
			columns: JSON.parse(this.defineMethods.getSessionStorage('Department')),
			searchVal: '',
			showSearch: false,
			dataList: [],
			peopleList: [],
			scrollHeight: 0,
			offsetHeight: 0,
			isReachBottom: false,
			isLoad: false,
			pageIndex: 1,
			pageSzie: 9,
			departmentId: 0,
			pagerecords: 0,
		}
	},
	methods: {
		onLoad() {},
		async onSearch(val) {
			this.searchVal = val
			this.pageIndex = 1
			this.peopleList = []
			this.pagerecords = 0
			await this.getPeopleList()
			this.showSearch = false
		},
		setData(data) {
			this.showSearch = false
			this.searchVal = data.name
		},
		onConfirm(val) {
			this.departmentId = val.id
			this.showPicker = false
			this.department = val.text
			this.pageIndex = 1
			this.peopleList = []
			this.pagerecords = 0
			this.getPeopleList()
		},
		async getPeopleList() {
			this.isReachBottom = false
			let result = await GetByDepartmentOrName(
				this.departmentId,
				this.searchVal,
				this.pageIndex,
				this.pageSzie
			)
			this.pagerecords = result.Pagerecords //总数
			if (result.ResultCode === 206) {
				console.log('没数据')
				this.isLoad = false
				this.isReachBottom = true
				return
			}
			this.peopleList.push(...result.Data)
			if (this.peopleList.length >= this.pagerecords) {
				this.isLoad = false
				this.isReachBottom = true
				console.log('加载完毕' + this.peopleList.length + '_' + this.pagerecords)
				return
			}
			this.isLoad = true
			console.log(this.pageIndex)
		},
		// 翻页函数
		turnpageFunc(e) {
			let reachBottomDistance = 100 //距离底部100像素触发
			let scrollTop = e.target.scrollTop
			let scrollHeight = e.target.scrollHeight
			let offsetHeight = Math.ceil(e.target.getBoundingClientRect().height)
			let currentHeight = scrollTop + offsetHeight + reachBottomDistance
			if (currentHeight < this.scrollHeight && this.isReachBottom) {
				this.isReachBottom = false
			}
			if (this.isReachBottom) {
				return
			}
			if (currentHeight >= scrollHeight) {
				this.pageIndex++
				console.log('触底了,可以翻页加载数据了')
				this.getPeopleList()
			}
		},
	},
	computed: {},
	mounted() {
		// console.log(this.$store.state.Department);
		let dom = document.querySelector('.staff-list')
		this.scrollHeight = dom.scrollHeight
		this.offsetHeight = Math.ceil(dom.getBoundingClientRect().height)
		// this.getPeopleList()
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.pageIndex = 1
			vm.pagerecords = 0
			vm.peopleList = []
			vm.getPeopleList()
		})
	},
}
</script>
<style lang="scss" scoped>
@import '../../../style/public.scss';
.staff {
	width: 100%;
	height: 100%;
	position: relative;
	> div:first-child {
		.return-icon {
			position: absolute;
			top: 15px;
			left: 8px;
			z-index: 5;
			font-size: 28px;
		}
	}
	&-list {
		width: 100%;
		height: calc(100% - 218px);
		overflow-y: scroll;
		padding-bottom: 40px;
		.data-status {
			> div:last-child {
				height: 18px;
				display: flex;
				flex-flow: row nowrap;
				justify-content: center;
				align-content: center;
				width: 100%;
				margin-top: 16px;
				color: $gray;
				font-size: 14px;
				> div:first-child {
					align-self: flex-end;
					margin-right: 10px;
				}
			}
		}
	}
	.s-list-item {
		width: 100%;
		.van-image {
			border-radius: 5px 5px 0px 0px;
			overflow: hidden;
			margin: 0;
			height: 107px;
			width: 100%;
		}

		.staff-name {
			font-size: 13px;
			font-weight: 400;
			color: $titleColor;
			padding-left: 10px;
			padding-right: 10px;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			> span {
				display: inline-block;
				width: 75%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			> i {
				margin-left: 5px;
				font-size: 14px;
				color: $gray;
			}
		}
		.staff-occupation {
			font-size: 12px;
			font-weight: 400;
			color: $subTitleColor;
			transform: scale(0.95);
			margin-top: 3px;
			padding-left: 10px;
			margin-bottom: 7px;
		}
	}
	.staff-search {
		margin: 16px 0 16px 16px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

		> div:first-child {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			background: #fff;
			border-radius: 8px;
			padding: 6px 16px;
			> div:first-child {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
				border-right: 1px solid $gray;
				padding-right: 10px;
				font-size: 14px;
				font-weight: 400;
				color: $titleColor;
				> span {
					white-space: nowrap;
				}
				> i {
					margin-left: 1px;
					align-self: flex-end;
					padding-bottom: 2px;
				}
			}
			.van-search {
				padding: 0;
			}
			.van-search,
			.van-search__content {
				background: transparent;
			}
		}
		> i {
			display: inline-block;
			width: 28px;
			height: 28px;
			margin-left: 8px;
			margin-right: 8px;
			text-align: center;
			line-height: 28px;
			font-size: 22px;
			color: #949494;
		}
	}
}
</style>
<style lang="scss">
.staff {
	.van-grid-item__content {
		padding: 0;
	}
	.van-grid-item {
		border-radius: 5px;
		overflow: hidden;
	}
}
</style>